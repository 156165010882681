@import '../../assets/Stylesheets/mixins.module.scss';

.Section {
    max-width: 100%;
    padding: 4em 2em;

}

.SectionContainer {

    @include tablet-view {
        border: none !important;
        outline:0px !important;
    }

}

.SectionContainer {
    display: flex;
    box-sizing: border-box;
    max-width: 1000px;
    margin: 0 auto;
    background-color: white;

    &.ReverseFlex {
        flex-direction: row-reverse;
    }

    .SectionChild {
        img {
            position: relative;
            top: 50%;
            transform: translateY(-50%);
        }

        margin: 2em;

        &.NoMarginLeft {
            margin-left: 0;
        }

        &.NoMarginRight {
            margin-right: 0;
        }

        &:nth-of-type(1) {
            flex-basis: 30%;
        }

        &:nth-of-type(2) {
            flex: 1;
        }

    }

    .SectionContentContainer {
        display: flex;
        flex-direction: column;

        .SectionContentTitle {
            font-family: "Montserrat Alternates";
            font-weight: 900;
            color: #BB0122;
        }

        .SectionContent {
            font-family: "Montserrat";
            font-size: 1em;
            color: #555555;
            flex-grow: 1;
        }

        .MoreButton {
            border: 3px solid white;
            padding: 3px 10px 3px 9px;
            line-height: 1.5rem;
            border-radius: 3px;
            font-size: 14px;
            font-weight: 800;
            list-style: none;
            color: white;
            font-family: 'Montserrat';
            background-color: #BB0122;
            box-sizing: border-box;
            align-self: flex-start;
            min-width: 10rem;

            &:hover {
                box-shadow: 2px 2px 8px #00000044;
            }
        }
    }
}

.ImageContainer {

    img {
        min-width: 200px;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

@media (max-width: 549px) {

    .Section {
        margin: 2em 0;
        padding: 2em 1em;
    

    .SectionContainer {
        border: 0px;
        box-sizing: border-box;
        max-width: 1000px;
        margin: 0 auto;
        background-color: white;
    
        .SectionChild {
            padding: 1em;   
            margin: 0;
        }
    
        .SectionContentContainer {
            padding: 1rem;

            .SectionContentTitle {
                font-family: "Montserrat Alternates";
                font-weight: 900;
                color: #BB0122;
                margin-bottom: 1em;
            }
    
            .SectionContent {
                font-family: "Montserrat";
                font-size: 1em;
                color: #555555;
            }
    
            .ImageContainer {

                position: relative;
                padding-bottom: 2em;
                img {
                    width: 100%;
                    height: auto;
                }
            }

            .MoreButtonContainer {
                align-self: center;
                position: absolute;
                bottom: 0;
                left: 50%;
            }

            .MoreButton {
                border: 3px solid white;
                padding: 3px 10px 3px 9px;
                line-height: 1.5rem;
                border-radius: 3px;
                font-size: 14px;
                font-weight: 800;
                list-style: none;
                color: white;
                font-family: 'Montserrat';
                background-color: #BB0122;
                box-sizing: border-box;
                position: relative;
                left: -50%;
                margin-bottom: 1em;
            }
        }
    }
}
}