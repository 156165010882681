html,body {
  padding: 0;
  margin: 0;
  font-size: 100%;
  line-height: 1.4;
  
}

html {
  font-size: 1rem;
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Arizonia';
  src: url('assets/Fonts/Arizonia-Regular.ttf') format('truetype');
}

@import url("https://fonts.googleapis.com/css?family=Montserrat&display=swap");
@import url("http://fonts.googleapis.com/css?family=Montez&text=,&display=swap" );