$Palette: (
    light-green: rgb(1, 139, 121),
    dark-green:  rgb(1, 70, 60),
    nav-purple: #987284
);

$Navigation: (
    ToolbarHeight: 150px,
    SubToolbarHeight: 70px
);

