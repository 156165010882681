button.Avatar {
  padding: 0;
  margin: 0;
  border: 1px solid transparent;
  background: none;
  outline: none;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  cursor: pointer;

}

.Pulse {
  animation: pulse 1.2s ease-in-out infinite;
}

.Avatar:focus {
  outline: none;
}

.Avatar-img {
  height: 100%;
  width: 100%;
  border-radius: 50%;
  transition: .1s;
}

.Avatar-img:hover {
  transform: scale(1.15);
  transition: .2s;
}


@keyframes pulse {

  40% {
      transform: scale(1.2);
  }

  100% {
      transform: scale(1);
  }

}