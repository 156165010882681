@import "../../../assets/Stylesheets/mixins.module.scss";

.CardContainer {
  height: 100%;
  padding: 1rem;
  width: 100%;
}

.Card {
    border: 1px solid #dc3545;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    height: 100%;
}

.Image {
  display: block;
  width: 100%;
  flex: 2 1;
  padding: 1rem 1rem .5rem;
  object-fit: cover;
  border-radius: 300px;
  height: 300px;
  object-fit: cover;
  position: relative;

  @include tablet-view {
    width: 300px;

  }
}

.ProductName {
  display: inline-block;
  color: #dc3545;
  border: 2px solid #f5ebff;
  background-color: #fffbf0;
  padding: .25rem .5rem;
  text-align: center;
  flex: 1;
  font-family: "montserrat";
  font-weight: 900;
  font-size: 1.2rem;
  margin: .5rem 1rem;

}

.ProductDetails {
  flex: 5;
  text-align: center;
  font-family: "montserrat";
  font-weight: 400;
  padding: .5rem 1rem 1rem;
  line-height: 1.3rem;
  font-size: 14px;
  color: #535353;
}

@media (max-width: 699px) {

}

@media (min-width: 700px) and (max-width: 999px) {

}

@media (min-width: 1000px) and (max-width: 1199px) {
    .Card {
        max-width: 300px;
    }
}

@media (min-width: 1200px) {
    .Card {
        max-width: 300px;
    }
}
  
