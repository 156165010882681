.Banner {
    display: none;

}

.Column {
    flex: 1;
}

.Close {
    font-size: 18px;
    padding: 0px .5em;
    vertical-align: middle;
    transform: translateY(-2px);

}

@media (max-width: 799px) {
    .Banner {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #5800b7;
        color: white;
        font-family: 'montserrat';
        z-index: 1000;
        padding: .5em 1em .5em 1.5em;
        line-height: 1.2;
        position: relative;
        font-size: .8rem;
        text-align: center;
    }
}