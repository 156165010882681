@import '../../assets/Stylesheets/mixins.module.scss';

.Footer {
    background-color: #501d88;
    position: relative;
    text-align: center;
    padding-top: 4rem;
    font-family: 'montserrat';

    a {
        text-decoration: none;
        color: inherit;
        &:hover {
            color: #fffb;
        }
    }

    .FooterHeader {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        padding: 1.5rem .5rem 0rem;

        img {
            width: 4rem;
            height: auto;
            margin: 0 .5rem;
            display: inline-flex;
        }

        span {
            font-family: 'montserrat';
            font-weight: 900;
            font-size: 2rem;
            color: white;
            line-height: 1;
            margin: 0 .5rem;

        }

        &:after {
            content: none;
        }
    }

    .QuickLinksHeader {
        font-weight: 700;
        font-size: 1.2rem;
        margin-bottom: 1.25rem;    
        font-family: inherit;
        color: #f9f4ff;
    }

    .QuickLinksFlexContainer {
        display: flex;
        flex-direction: row;
        text-align: left;
        justify-content: center;
        font-family: inherit;

        @include mobile-view {
            flex-direction: column;
            text-align: center;

        }

        .QuickLinksColumn {
            list-style: none;
            color: #f9f4ff;
            padding: 0 2rem;

            .QuickLink {
                font-weight: 400;
                font-size: 1rem;
                line-height: 2;
            }

        }
    }

    .MediaIconContainer {
        display: flex;
        justify-content: center;
        margin: 2rem;

        .MediaIcon {
            margin: 1rem;
            transition: .1s;
            cursor: pointer;
            width: 3.5rem;
            height: 3.5rem;

            -webkit-transform: perspective(1px) translateZ(0);
            transform: perspective(1px) translateZ(0);

            &:hover {
                transform: scale(1.3, 1.3);
                -webkit-transform: scale(1.3);
            }

            img {
                width: 100%;
                height: auto;
                display: inline-block;

            }
        }
    }

    .FootText {
        font-size: .8rem;
        font-weight: 300;
        color: #f9f4ff;
        padding-bottom: .5rem;
        font-family: inherit;

        p {
            margin-bottom: .25rem;
        }
    }
}

.WavyBorder {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
}

.WavyBorder svg {
    position: relative;
    display: block;
    width: calc(300% + 1.3px);
    height: 100px;
}

.WavyBorder .ShapeFill {
    fill: #FFFFFF;
}


/***** Animations *****/

@keyframes pulse {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(.8);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes surface {
    0% {
        transform: translateY(200%);
        animation-timing-function: cubic-bezier(0, .92, .69, .99);
    }

    30% {
        transform: translateY(-64%);
        animation-timing-function: cubic-bezier(.69, .01, .99, .58);
    }

    50% {
        transform: translateY(32%);
        animation-timing-function: cubic-bezier(.01, .42, .83, 1);
    }

    65% {
        transform: translateY(-32%);
        animation-timing-function: cubic-bezier(.36, .01, 1, .83);
    }

    75% {
        transform: translateY(16%);
        animation-timing-function: cubic-bezier(0, .92, .69, .99);
    }

    85% {
        transform: translateY(-8%);
        animation-timing-function: cubic-bezier(.26, .02, .99, .66);
    }

    93% {
        transform: translateY(8%);
        animation-timing-function: cubic-bezier(0, .92, .69, .99);
    }

    100% {
        transform: translateY(0%);
        animation-timing-function: cubic-bezier(.97, .01, .99, .61);
    }
}