/* Demo specific style begin */
.LoaderDots {
    position: absolute;
    top: calc(50% - 10px);
    left: calc(50% - 40px);
  }
  /* Demo specific style end */
  
  .LoaderDots:before, .LoaderDots:after {
    height: 20px;
    width: 45px;
    background-image: radial-gradient(closest-side, rgb(178, 51, 247) 100%, transparent), radial-gradient(closest-side, rgb(0, 183, 177) 100%, transparent);
    background-position: 0%, 100%;
    background-size: 20px 20px, 20px 20px;
    background-repeat: no-repeat;
    position: absolute;
    animation-duration: 0.8s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-name: spin, onOff;
    content: '';
  }
  
  .LoaderDots:before {
    animation-direction: normal, reverse;
  }
  .LoaderDots:after {
    right: 0px;
    animation-direction: reverse, normal;
    background-image: radial-gradient(closest-side, rgb(225, 0, 0) 100%, transparent), radial-gradient(closest-side, rgb(178, 109, 13) 100%, transparent);
  }
  
  .LoaderDots {
    height: 20px;
    width: 70px;
    background-image: radial-gradient(closest-side, rgb(224, 61, 242) 100%, transparent), radial-gradient(closest-side, rgb(255, 99, 99) 100%, transparent);
    background-position: 0%, 100%;
    background-size: 20px 20px, 20px 20px;
    background-repeat: no-repeat;
    position: absolute;
    animation: toggle 0.8s ease-in-out infinite;
  }
  
  @keyframes spin {
      0% { transform: rotate(0deg)   }
     50% { transform: rotate(180deg) }
    100% { transform: rotate(180deg) }
  }
  
  @keyframes onOff {
      0% { opacity: 0; }
     49% { opacity: 0; }
     50% { opacity: 1; }
    100% { opacity: 1; }
  }
  
  @keyframes toggle {
    0%    { background-size: 0px 0px, 20px 20px }
    49.9% { background-size: 0px 0px, 20px 20px }
    50%   { background-size: 20px 20px, 0px 0px }
    100%  { background-size: 20px 20px, 0px 0px }
  }
  