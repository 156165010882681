@import '../../../assets/Stylesheets/variables.module';
@import '../../../assets/Stylesheets/mixins.module.scss';


.NavAnchor:focus,
.NavAnchor:hover,
.NavSubAnchor:focus,
.NavSubAnchor:hover{
    color: #a767ff;
    text-decoration: none;
    transition: color .2s;
    cursor: pointer;
}

.NavAnchor,
.NavSubAnchor,
.SubList .SubListItem {
    position: relative;
    font-size: 16px;
    z-index: 15;
    font-weight: 500;
    text-decoration: none;
    list-style: none;
    color: #412461;
    font-family: 'Montserrat';
    font-display: block;

}

.SubList .SubListItem {
    position: relative;
    padding: 1rem 2.5rem;
    line-height: 1;

    span {
        font-family: 'montserrat';
        font-size: 16px;
        font-weight: 300;
    }
}

div.SubList {
    display: flex;
    text-align: left;
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
    justify-content: space-between;
}

li a {
    white-space: nowrap;
}

.subMenuColumn {
    display: inline-block;
    padding: 0px;
}

.SubListItemContainer {
    display: flex;
}

.SubMenuImageContainer {
    width: auto;
    text-align: center;
    height: 9rem;
    flex-shrink: 1;

    @include burger-menu-view {
        display: none;
    }
}

.subMenuImage {
    border-radius: 20px;
    height: 100%;
}

.SubMenuContainer {
    width: 100%;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    background-color: #ffffff;
    z-index: 0;
    top: 120px;
    left: 0;
    border-top: 1px dotted #a767ff;
    border-bottom: 1px dotted #a767ff;
    padding: 1.5rem;
    box-shadow: 0px 5px 6px #0000005e;
    transition: .1s;
    transition-delay: .25s;

    @include burger-menu-view {
        border: none;
        box-shadow: none;
        position: relative;
        padding: 0px;
        top: 0px;
        opacity: 1;
        visibility: visible;

    }

    &.Hide {
        display: none;
    }

}

[class*='SubToolbar'] .SubMenuContainer {
    top: map-get($Navigation, SubToolbarHeight);
}

span.Button {
    border: 3px solid #412461;
    display: inline-block;
    padding: 3px 15px 3px 9px;
    line-height: 1.5rem;
    vertical-align: middle;
    border-radius: 3px;

    @include burger-menu-view {
        line-height: 1;
        margin-top: .6rem;
    }

    .Icon {
        height: 1.5rem;
        margin-right: .4rem;
        padding-bottom: 1px;
        transition: all .1s, filter 0s;
    }

    &:hover {
        background-color: #412461;
        color: white;

        .Icon {
            transform: rotate(15deg);
            transition: all .1s, filter 0s;
            filter:brightness(10) ;
        }
    }
}



@media (min-width: 750px) {
    // li:hover .NavAnchor ul.SubMenuContainer,
    // nav li:hover ul.SubMenuContainer,
    // li.ListItem:hover ul.SubMenuContainer,
    // div.SubList:hover {
    //     visibility: visible;
    //     opacity: 1;
    //     display: block;
    // }
}

@media (max-width: 749px) {

    .NavAnchor {
        display: block;
        line-height: 2.6;
    }

    .ToggleSubMenu::after {
        content: " ›"
    }

    div.SubList {
        position: relative;
        background-color: #ffffff;
        z-index: 30;
        text-align: left;
        box-sizing: content-box;
        top: 0px;
        box-shadow: none;
        width: 100%;
        left: 0px;
        display: block;
        visibility: visible;
        opacity: 1;
        padding: 0px;
    }

    .SubList .SubListItem {
        justify-items: center;
        padding: 0;
        display: block;
        line-height: 1;
    }

    .NavSubAnchor {
        padding: 0 50px;
        line-height: 2.4;
    }

}