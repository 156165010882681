.BioSection {
    background-image: url("/images/bio/raspberries.jpg");
    min-height: 500px;
}

.BioImage {

}

.BioDescription {

}

div[class*='carousel'][class*='slider'] {
    margin: 0 0 50px 0;
}

[class='slide'][class='selected'] {
    background-color: white;
}