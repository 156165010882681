@import '../../../assets/Stylesheets/variables.module';

.List {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    height: map-get($Navigation, height);
    display: flex;
}

.ListItem::before {
    content: "";
    display: inline-block;
    height: 100%;
    vertical-align: middle;
}

.ListItem, .ListItemLogo {
    display: inline-block;
    vertical-align: middle;
    z-index: 15;
    position: static;
    flex-grow: 1;
    text-align: center;

    a {
        text-decoration: none;
    }
}

.ListItem:hover div {

    transition-delay: 0s;
    opacity: 1;
    visibility: visible;

}

.ListItemLogo {
    margin: 10px 0;
}

.Left, .Right, .Center {
    flex: 4;
    display: flex;
    justify-content: space-around;
}

.Center {
    flex: 3;
}

.Menu {
    width: 100%;
  }

.Front {
    z-index: 20;
}

@media (max-width: 749px) {
    
    .List {
        display: block;
        background-color: white;
        padding-bottom: 2rem;
    }

    .ListItem {
        display: block;
        position: relative;
        margin: 0;
        padding-left: 70px;
        text-align: left;
    }

    .ListItemLogo {
        padding-left: 20px;
    }

    .Menu {
        position: absolute;
        width: 100%;
        height: 100%;
        left: -100%;
        z-index: 10000;
        top: 0px;
        background-color: white;        
        display: block;

        transform: translateX(100%);
        -webkit-transform: translateX(100%);

        transition: all .7s cubic-bezier(0.68, -0.55, 0.265, 1.55);
        -webkit-transition: all .7s cubic-bezier(0.68, -0.55, 0.265, 1.55);

        padding-bottom: 20px;
        /* box-shadow: 2px 2px 10px black; */
    }

    .Hide {
        transform: translateX(0px);
        -webkit-transform: translateX(0px);

        transition: all .5s cubic-bezier(.75,-0.4,.26,1.55);
        -webkit-transition: all .5s cubic-bezier(.75,-0.4,.26,1.55);
        
    }

    .ListItem::before {
        content: none;
    }

    .FirstRow {
        display: flex;
        justify-content: space-between;
        height: 70px;
        align-items: center;
    }

    .HamburgerContainer {
        position: relative;

    }
}

