@import "../../assets/Stylesheets/mixins.module.scss";

.Heading {
    padding: 50px;
    line-height: 1.6;
}

.Heading span {
    font-size: 18px;
}

.ReviewRequest {
    margin-top: 30px;
}

.ReviewButton {
    border: 2px solid black;
    color: black;
    border-radius: 5px;
    background-color: white;
    transition: .075s ease-in-out;
    width: 200px;
    text-align: center;
    height: 30px;
    overflow: hidden;

    &:hover {
        cursor: pointer;
    }
}

.item {
    display: inline-block;
    width: 30%;
}

.ReviewRequestButtonContainer {
    width: clamp(375px, 100%, 1100px);
    height: auto;
    margin: 0px auto;
    overflow: hidden;
    position: relative;
    text-align: center;
    z-index: 2;
}

.ReviewRequestButton {
    font-family: 'montserrat';
    font-size: 16px;
    font-weight: 600;
    color: #87d8df;
    border: 3px solid #87d8df;
    background-color: white;
    margin: 0px auto;
    border-radius: 3px;
    text-align: center;
    display: inline-block;
    padding: .8rem .6rem;
    cursor: pointer;
    height: auto;

    &.ButtonHoverable:hover {
        color: white !important;
        background-color: #87d8df !important;
    }
}

.ReviewRequestButtonText {
    white-space: nowrap;
}

.ReviewRequestFormContainer {

    margin: 2rem;
    opacity: 0;
    text-align: left;
    display: none;
    transform: translateY(-10px);
    color: "#676767";

    .ReviewRequestFormHeading {
        font-weight: 200;
        padding-bottom: 2rem;
    }

    label {
        font-size: 14px;
        font-weight: 800;
    }



    input,
    textarea {
        box-shadow: 2px 2px 2px #dddddd;
        padding: 10px;
    }

    button {
        background-color: rgb(135, 216, 223);
        padding: .75rem 1rem;
        border: 0px;
    }
}

.TestBox {
    width: 75%;
    margin: 30px auto;
    border: 2px dotted pink;
    position: relative;
    background-color: white;

    .Tab {
        color: #777777;
        background-color: white;
        width: 60px;
        height: 30px;
        position: absolute;
        border: 2px dotted pink;
        border-radius: 55% 55% 50% 50% / 100% 100% 5% 5%;
        border-bottom: 2px solid white;
        top: -29px;
        left: 0;
        right: 0;
        margin: 0 auto;

        .TabText {

            transform: translate(-8px, -3.3rem);
            font-size: 7rem;
            line-height: 1;
            letter-spacing: -19px;
            font-family: 'Montez';
            text-align: center;

        }
    }

    .TestBoxInner {
        margin: 2rem;
        background-color: #f7f3ea;
        height: 300px;
    }
}

.CustomerReviewSection {
    max-width: 1200px;
    margin: 2rem auto;
    padding: 2rem;
    position: relative;
    background-color: #fee4d8;

    @include tablet-view {
        padding: 0 0 4rem;
        margin: 0 auto;
    }

    .CustomerReviewTitle {
        color: #ffb898;
        font-family: 'montserrat';
        font-weight: 800;
        font-size: clamp(2rem, 10vw, 5rem);
        transform: translate(-10%, 25%);
        transition: .1s;
        padding: 0;

        &:after {
            content:none;
        }

        @include tablet-view {
            padding:1rem;
            transform: translate(-10%, 35%);
            transition: .1s;
        }
    }

    .CustomerReviewSubTitle {
        position: relative;
        font-size: 1.2rem;
        font-family: 'montserrat';
        color: #606060;
        text-align: center;
    }
}