@import "../../assets/Stylesheets/mixins.module.scss";

.ReviewContainer {
    width: clamp(375px, calc(100% - 40px), 1200px);
    margin: 4rem auto;
    border: 2px solid #ffb898;
    position: relative;
    background-color: white;
    box-shadow: -10px -10px 0px white;

    &:nth-of-type(1) {
        margin: 2rem auto;
    }

    &:last-of-type {
        margin-bottom: 0;
    }

    @include burger-menu-view {
        width: clamp(375px, calc(100%), 1100px);

        border: 2px solid #ffb898;
        border-left: 0;
        border-right: 0;
    }


    .ReviewTab {
        color: #777777;
        background-color: white;
        width: 60px;
        height: 30px;
        position: absolute;
        border: 2px solid #ffb898;
        border-radius: 55% 55% 50% 50% / 100% 100% 5% 5%;
        border-bottom: 2px solid white;
        top: -29px;
        left: 0;
        right: 0;
        margin: 0 auto;
        box-shadow: -10px -3px white;

        .ReviewTabQuotes {

            transform: translate(-9px, -3.3rem);
            font-size: 7rem;
            line-height: 1;
            font-weight: 400;
            letter-spacing: -19px;
            font-family: 'Montez';
            text-align: center;

        }
    }

    .ReviewInner {
        margin: 1.5rem;
        background-color: #f7f3ea;
        background: url('/images/design/parchment-paper.jpg');
        background-size: cover;
        color: #5c5c5c;
        position: relative;

        &:before {
            content: "";
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            background-color: #fffd;
        }

        .FlexReviewContainer {
            position: relative;
            display: flex;
            padding: 2rem;
            font-family: "montserrat";
        }
    }
}

.FlexProfile {
    display: flex;
    flex-basis: 35%;
    justify-content: center;
    align-items: center;
    padding-right: 2rem;

    @include laptop-view {
        flex-basis: 30%;
    }

    @include burger-menu-view {
        justify-content: flex-end;
        padding-right: 0;
        margin-top: 1rem;
    }

    .Avatar {
        height: 40px;
        width: auto;
        border-radius: 50%;
        margin-right: .75rem;
    }

    .Name {
        font-size: 1rem;
        font-family: 'montserrat alternates';
        font-weight: 400;
    }
}

.ReviewContent {
    flex-basis: 70%;

    @include burger-menu-view {
        padding: 2rem;
        position: relative;
    }

    .Title {
        font-size: 1.5rem;
        font-weight: 700;
        margin: 0 0 .25rem 0;
    }

    .Product {
        display: flex;
        justify-content: space-between;
        font-size: .8rem;

        .ProductTitle {
            font-weight: 400;
        }

        .Date {
            font-weight: 400
        }
    }

    .Description {
        margin-top: 1rem;
        margin-bottom: 0;
    }

    .FlexThumbnail {
        display: flex;
        flex-wrap: wrap;
        margin-top: 1rem;

        .Thumbnail {
            height: 60px;
            max-width: 100px;
            padding: .25rem .5rem;

            &:first-of-type {
                padding-left: 0;
            }
        }
    }
}