.ReviewForm {

    input::-webkit-input-placeholder,
    textarea::-webkit-input-placeholder {
        opacity: .5;
    }
}

button.Button {
    margin: 0 1rem 1rem 0;
    white-space: nowrap;
    transition: .1s;
    background-color: #368b9e;
    border: 3px solid #368b9e;
    &:hover, &:focus, &:active {
        border: 3px  solid #368b9e;
        background-color: transparent;
        color: #368b9e;
        transition: .1s;
    }
}

.ButtonContainer {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.ReviewRequestFormHeading {
    font-weight: 800;
    padding: 0;
    color: #afd6db;
    font-size: clamp(2rem,8vw, 5rem);
    transform: translate(-5%, 5%);
    line-height: .9;

    &:after {
        content: none;
    }
}

.ReviewRequestFormSubHeading {
    position: relative;
    font-size: 1.2rem;
    font-family: 'montserrat';
    font-weight: 400;
    color: #606060;
    text-align: center;
    line-height: 1.3;
}

.LightPadding {
    padding: 1rem;
}

.ReviewForm [class*='form-control'] {
    background-color: #f0f8f9;


&:focus {
    color: #495057;
    background-color: #fff;
    border-color: #368b9e;
    outline: 0;
    box-shadow: none;
  }
}