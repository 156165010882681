@import '../../assets/Stylesheets/variables.module';

.ToolbarContainer {
    width: 100%;
    height: 120px;
    display: flex;
    justify-content: center;
    position: relative;
    z-index: 100;
    background-color: #ffffff;


    // background-image: linear-gradient(
    //     #ffffff, 
    //     #ffffffbb 19%, 
    //     #ffffff8a 34%, 
    //     #ffffff62 47%, 
    //     #ffffff48 56.5%, 
    //     #ffffff30 65%, 
    //     #ffffff20 73%, 
    //     #ffffff12 80.2%, 
    //     #ffffff0b 86.1%, 
    //     #ffffff05 91%, 
    //     #ffffff02 95.2%, 
    //     #ffffff01 98.2%,  
    //     #ffffff00);

}

.Toolbar {
    max-width: 1000px;
    position: static;
    display: flex;
    justify-content: center;
    padding: 0 20px;
    box-sizing: border-box;
    flex: 1;
}

.Logo {
    margin: 10px 0;
}

@media (max-width: 749px) {

    .ToolbarContainer {
        position: sticky;
        top: 0px;
        position: relative;
        height: 70px;
        background-color: white;
        box-shadow: 0;
    }

    .Toolbar {
        background-color: #ffffff00;
        width: 100%;
        box-shadow: 0;
        display: flex;
        justify-content: center;
    }

    .MobileToolbarItem {
        flex: 1;
        margin: 0;
        z-index: 4000;
    }

    .Left {
        position: absolute;
        left: 30px;
        top: 50%;
        transform: translateY(-50%);
    }

    .Center {
        height: 50px;
        position: relative;
    }


}

/***** SubToolBar styles *****/

@import '../../assets/Stylesheets/variables.module';

.SubToolbarContainer {
    width: 100%;
    height: map-get($Navigation, SubToolbarHeight);
    display: flex;
    justify-content: center;
    position: fixed;
    z-index: 100;
    background-color: #ffffff;
    opacity: 0;
    visibility: hidden;
    transform: translateY(-60px);
    transition: .25s;
    top: 0;
}

.EmptyToolbarContainer {
    width: 100%;
    height: map-get($Navigation, SubToolbarHeight);
    display: flex;
    justify-content: center;
    position: fixed;
    z-index: 100;
    background-color: #ffffff;
    opacity: 0;
    visibility: hidden;
    transform: translateY(-60px);
    transition: 0;
    top: 0;
}

.SubToolbar {
    max-width: 1200px;
    position: static;
    display: flex;
    justify-content: center;
    padding: 0 20px;
    box-sizing: border-box;
    flex: 1;

}

.SubToolbarDisplay {
    visibility: visible;
    opacity: 1;
    transform: translateY(0px);
    transition: .25s;
}


@media (max-width: 699px) {
    // .SubToolbarContainer {
    //     height: 0px;
    // }

    // .SubToolbar {
    //     width: 100%;
    //     justify-content: space-between;
    // }
}

@media (min-width: 700px) and (max-width: 999px) {

}

@media (min-width: 1000px) and (max-width: 1199px) {

}

@media (min-width: 1200px) {

}