.Dropzone {
  text-align: center;
  padding: 20px;
  border: 3px dashed #eeeeee;
  background-color: #fafafa;
  color: #bdbdbd;
  display: flex;
  justify-content: center;
  align-items: center;
}

.DropZoneText {
  font-size: .8rem;
}

.ImageUploadIcon {
  height: 3rem;
  width: auto;
  padding-right: 1rem;
}

.ThumbsContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 16px;
}

.Thumb {
  display: inline-flex;
  border-radius: 2px;
  border: 1px solid #eaeaea;
  margin-bottom: 8px;
  margin-right: 8px;
  width: 100px;
  height: 100px;
  padding: 4px;
  box-sizing: border-box;
}

.ThumbInner {
  display: flex;
  min-width: 0px;
  overflow: hidden;
  position: relative;
}

.ThumbInnerClose {
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 3px;
  background-color: #ffffff99;
  line-height: .9;
  cursor: pointer;
}

.Img {
  display: block;
  width: auto;
}