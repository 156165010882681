@import './assets/Stylesheets/mixins.module.scss';

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
    color: white;
  }
  to {
    transform: rotate(360deg);
  }
}

html{
  margin-top:0;
  padding-top:0;
}
body{
  margin-top:0;
  padding-top:0;
}

.Dim {
  opacity: .5;
}

.Spiral {
  height: 200px;
  width: 200px;
  position: absolute;
  top: 870px;
  left: 10px;
}

body h2 {
  font-family: "montserrat";
  font-weight: 200;
  color: #5800B7;
  padding: 1rem 1.5rem;
  text-align: center;
  
}

body a:hover {
  text-decoration: none;
}
// h2:after {
//   width: 1.5rem;
//   height: 1px;
//   content: '';
//   margin: 2rem auto;
//   display: block;
//   background-color: #bbbbbb;
//   @include tablet-view {
//     margin: 2rem auto 1rem auto;
//   }
// }

body [class*='form-group'] { 
  margin-bottom: 1.5rem;
}

