@import '../../assets/Stylesheets/mixins.module.scss';

.Title {
    text-align: center;
    font-family: "montserrat";
    font-weight: 200;
    color: #5800B7;
    // transform: translateY(1.8rem);
    background-color: white;
    padding: 1rem 1.5rem;
    text-align: center;
    margin-top: 2rem;

    &:after {
        width: 1.5rem;
        height: 1px;
        content: '';
        margin: 2rem auto;
        display: block;
        background-color: #bbbbbb;
    }

    @include tablet-view {
        font-size: 32px;
        color: #5800b7;
        text-shadow: none;
        font-weight: 200;
        font-family: 'montserrat alternates';
        background-color: transparent;
        padding: 0;
        margin: 0;
        &:after {
            content: none;

        }
    }
}


@include tablet-view {
    .TitleContainer {
        // background: linear-gradient(90deg, #b7009608, #5800b70b);
        background: #5800b70d no-repeat;
        clip-path: ellipse(70% 100% at 50% 100%);
        background-size: cover;
        padding: 1.75rem 2rem 1rem;
    }
}

.BioSection {
    background-image: url("/images/bio/bio-bg.jpg");
    min-height: 500px;
    background-repeat: no-repeat;
    background-size: cover;
    margin: 0px 50px 200px 50px;
    border: 2px solid pink;
    font-family: "montserrat";

}

.BioSectionOuter {
    border: 20px solid white;
    padding: 50px;

}

.BioSectionInner {
    height: 100%;
    display: flex;
    justify-content: center;
    border: 30px solid white;
    flex-wrap: wrap;
}

.BioImageContainer {
    display: inline-block;
    flex: 1;
    min-width: 300px;
    max-height: 655px;
    position: relative;
}

.BioImage {
    width: 100%;
}

.BioDescription {
    position: relative;
    color: #31003f;
    font-family: inherit;
    padding: 0 50px;
    background-color: #fffffff0;
    margin: 0px;
    line-height: 1.5em;
    flex: 1;
    min-height: 300px;
    min-width: 300px;

    p {
        text-indent: 2rem;
        line-height: 2;
    }
}

.BioDescription div {
    // p {
    //     text-align: justify;
    //     text-justify: inter-word;
    // }
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

@media (max-width: 1252px) {
    .BioSection {
        background-image: url("/images/bio/bio-bg.jpg");
        min-height: 500px;
        background-repeat: no-repeat;
        background-size: cover;
        margin: 0px 50px 200px 50px;
        border: 2px solid pink;

    }

    .BioSectionInner {
        height: 100%;
        padding: 50px;
        display: block;
        justify-content: center;
        border: 20px solid white;
        padding: 0;
    }

    .BioImageContainer {
        display: block;
        flex: 1;
        min-width: 300px;
        max-height: 655px;
    }

    .BioImage {
        width: 100%;
    }

    .BioDescription {
        display: block;
        position: relative;
        color: #31003f;
        font-family: inherit;
        padding: 0px;
        background-color: #ffffffeb;
        margin: 0px;
        min-width: 300px;
        min-height: 500px;
    }

    .BioDescription div {
        position: absolute;
        padding: 50px;
        top: 50%;
        transform: translateY(-50%);
    }
}

@media (max-width: 800px) {
    .BioDescription {
        min-height: 900px;
    }
}

@include tablet-view {
    .BioSection {
        margin: 0px;
        border: none;
    }

    .BioSectionOuter {
        border: none;
        padding: 0px;
    }

    .BioSectionInner {
        height: 100%;
        padding: 0px;
        display: block;
        justify-content: center;
        border: 0px;
        padding: 0;
    }

    .BioDescription {
        min-height: 0px;
    }

    .BioDescription div {
        position: relative;
        padding: 50px;
        top: 0%;
        transform: translateY(0%);
    }
}