@mixin mobile-view {
    @media (max-width: 549px) { @content; }
  }
  
  @mixin mobile-landscape-view {
    @media (max-width: 599px) { @content;}
  }
  
  @mixin burger-menu-view {
    @media (max-width: 749px) { @content;}
  }

  @mixin tablet-view {
    @media (max-width: 799px) { @content; }
  }
  
  @mixin laptop-view {
    @media (max-width: 999px) { @content; }
  }
  
  @mixin desktop-view {
    @media (max-width: 1199px) { @content; }
  }