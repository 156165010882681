.ProductGridContainer {
}

.ProductGrid {
    position: relative;
    top: 70%;
    display: grid;
    grid-template-columns: 100%;
    column-gap: 0;
    max-width: 1200px;
    margin: 0 auto;
}


@media (max-width: 699px) {
    .ProductGrid {
        grid-template-columns: 100%;
    }
}

@media (min-width: 700px) and (max-width: 999px) {
    .ProductGrid {
        grid-template-columns: 50% 50%;
        max-width: 700px;
    }
}

@media (min-width: 1000px) and (max-width: 1199px) {
    .ProductGrid {
        grid-template-columns: 33.3% 33.3% 33.3%;
        max-width: 1000px
    }
}

@media (min-width: 1200px) {
    .ProductGrid {
        grid-template-columns: 25% 25% 25% 25%;
        max-width: 1200px;
    }
}
  