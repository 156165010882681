
.Container {
    display: relative;
    grid-template-columns: repeat(14, 1fr);
    grid-template-rows: 20% 20% auto auto auto;
    max-width: 1200px;
    margin: 0 auto;
    overflow: hidden;
}



.Bg {
    grid-area: 1/1/-1/-1;
}

.Bg .BgImage {
    width: 100%;
    height: 30em;
    background-size: cover;
    background-position: center;
}

@media (max-width: 799px) {
    .Bg div.BgImage {
        width: 100%;
        height: 20em;
        background-size: cover;
        background-position: center;
    }
}

.LineBreak {
    width: 100%;
    border-bottom: 2px dotted #EF3B97;
    height: 1px;
    z-index: -2;
    transform: translateY(25px);
}

.OverlayContainer {
    transform: translateY(-50%);
}

.OverlayAbove,
.OverlayBelow {
    position: relative;
}

.OverlayBelow:after {
    height: 60%;
    width: 130%;
    position: absolute;
    top: calc(50% + 22px);
    left: -15%;
    background-color: white;
    background-size: 100%;
    content: "";
    z-index: -1;
    border-bottom: 2px dotted #EF3B97;
    border-left: 2px dotted #EF3B97;
    border-right: 2px dotted #EF3B97;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-top: 2px solid white;
    pointer-events: none;
}

.OverlayBelowBackdrop {
    z-index: -3;
    background-color: white;
    padding: 60px 60px 0 60px;

}

.OverlayAbove:after {
    height: 100%;
    width: 130%;
    position: absolute;
    top: calc(-50% + 2px);
    left: -15%;
    background-color: white;
    background-size: 100%;
    content: "";
    z-index: -1;
    border-top: 2px dotted #EF3B97;
    border-left: 2px dotted #EF3B97;
    border-right: 2px dotted #EF3B97;
    border-bottom: 4px solid white;
    pointer-events: none;
    // box-shadow: -20px -20px 0 20px white, 20px -20px 0 20px white;
}

.Button {
    display: block;
    margin: 0 auto;
    border: 3px solid white;
    padding: 3px 10px 3px 9px;
    line-height: 1.5rem;
    border-radius: 3px;
    font-size: 16px;
    font-weight: 800;
    list-style: none;
    color: white;
    font-family: 'Montserrat';
    background-color: #AC3CF5;
    box-shadow: 2px 2px 8px #00000066;
    width: auto;
    box-sizing: border-box;

    img {
        height: 1.5rem;
        padding-right: .4rem;
        padding-bottom: 1px;
    }
}

.LowerTitleContainer {
    text-align: center;
    position: relative;
    box-sizing: content-box;
    transform: translateY(calc(-3.375em - 2px));

    .TitleBox {
        display: inline-block;
        margin: 0 auto;
        padding: 1.5em 1.75em 0 1.75em;
        position: relative;
        text-align: center;
        box-sizing: content-box;

        .Title {
            display: inline-block;
            margin: 0 auto;
            background: none;
            border-bottom: 2px solid white;
            padding: .25em .75em 0 .75em;
            z-index: 1;
        }

        .Title:before {
            background: white;
            position: absolute;
            width: 100%;
            height: 100%;
            bottom: 0;
            left: 0em;
            z-index: -5;
            content: "";
        }
    }

    .LineBreak {
        position: relative;
        bottom: 2px;
        transform: none;
    }
}

.TitleContainer {
    background: white;
    padding: .5rem 1rem;
    // backdrop-filter: blur(4px);
    grid-column: 2/ 10;
    grid-row: 3/4;
    text-align: center;
}

.Title {
    font-family: "Montserrat";
    font-weight: 200;
    color: #5800b7;
    line-height: 1;
    margin: 0;
    text-shadow: -2px -1px white, 1px 2px white, 2px 1px white, -1px -2px white, -2px 1px white, -1px 2px white, 2px -1px white, 1px -2px white;
    font-size: clamp(30px, 3.2vw, 42px);

}

.Subtitle {
    padding: .5rem 1rem;
    grid-column: 3/9;
    grid-row: 4;
    background: #5800b7;
    color: white;
    transform: translate(0, -.5em);
    font-family: "Montserrat";
    text-align: center;
    line-height: 1.4;
    font-size: 12px;
    outline: 5px solid white;
}

@media (max-width: 799px) {
    .TitleContainer {
        border-width: 3px;
        padding: calc(.5rem) calc(.75em);
        grid-column: 3/ -3;
        text-align: center;
        background-color: #ffffff77;
    }

    .Title {
        font-family: "American Typewriter";
        font-weight: 900;
        color: #5800b7;
        line-height: .95;
        margin: 0;

    }

    .Subtitle {
        display: none;

    }
}

@media (min-width: 700px) and (max-width: 999px) {

    .TitleContainer {
        padding: 1rem 2 rem;
        grid-row: 3/4;
    }
}

@media (max-width: 799px) {
    .TitleContainer {
        // background: linear-gradient(90deg, #b7009608, #5800b70b);
        background: #5800b70d no-repeat;
        clip-path:ellipse(70% 100% at 50% 100%);
        background-size: cover;
        padding: 1.75rem 2rem 1rem;


        .Title {
            font-size: 32px;
            color: #5800b7;
            text-shadow: none;
            font-weight: 200;
            font-family: 'montserrat alternates';

        }
    }
}

.HeroContainer {

}

.Hero {
    position: relative;
    width: 100%;
    height: 600px;
    background-size: cover;
    background-position: center;
    max-width: 1200px;
    margin: 0 auto;
    overflow: hidden;
}

.TitleAndSubContainer {
    bottom: -5px;
    position:absolute;
    left: 5vw;
    width: clamp(250px, 40vw, 500px);
    padding: 10px 10px 0;

}



.TitleD{
    color: #5800b7;
    background-color: white;
    font-family: 'montserrat';
    font-weight: 200;
    text-align: center;
    padding: 1rem ;
    font-size: clamp(32px, 3.2vw, 36px);
    margin: 0;
}



.SubTitleD, .TagLineD {
    padding: 1rem 1.5rem;
    background: #5800b7;
    color: white;
    font-family: "Montserrat";
    text-align: center;
    line-height: 1.8;
    font-size: 12px;
    border: 5px solid white;
    border-bottom: 0;
    border-top: 0;
    margin:0;
}

.TagLineD {
    padding-top: 0;
    padding-bottom: 75px;
    border: 5px solid white;
    border-top: 0;
    color: #cccccc;
    font-size:10px;

}

.SubTitleContainer {

}

.WavyDivider {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
}

.WavyDivider svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 50px;
}

.WavyDivider .ShapeFill {
    fill: #FFFFFF;
}

@media (max-width: 799px) {
    .TitleAndSubContainer {
        width: 100%;
        padding: 10px 10px 0;
        position: relative;
        left:0;
        bottom:0;
        padding:0;
        margin:0;
    }

    .SubTitleD, .TagLineD {
        border:0;
    }

    .WavyDivider .ShapeFill {
        fill: #FFFFFF33;
    }

}