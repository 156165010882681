.Packages {
    margin: 4rem auto;
    overflow: hidden;

    h2 {
        padding: .5rem 0rem;
        text-align: left;
    }
}


.OuterFlexRow {
    display: flex;
    height: auto;
    min-height: 20rem;
    max-height: 40rem;

    &>div:nth-of-type(2n) {
        flex-basis: 100%;
        max-width: 800px;
        flex-shrink: 0;
    }

}

@media (max-width: 699px) {
    .InnerFlexRow {
        flex-direction: column;
    }
}

.InnerFlexRow {
    display: flex;

    .InnerFlexColumn {
        padding: 1rem;
    }

    .ImageColumn {
        align-self: center;
    }

    .PartyImage {

        height: auto;
        padding: 1rem;
        background-color: white;
        align-self: center;

        img {
            max-width: 15rem;
            height: auto;
        }
    }

    .Details {
        padding: 2rem;
        display: flex;
        flex-direction: column;
        position: relative;
        width: 100%;

        .PackageTitle {
            font-family: "montserrat alternates";
            color: #ee3a97;
            font-weight: 900;
            margin: 0;
            font-size: 1.75rem;
        }

        .PackagePricing {
            font-family: "montserrat";
            font-weight: 200;
            font-size: 1.2rem;

            .BoldPricing {
                font-weight: 700;
            }
        }

        .PackageSavings {
            border: 4px solid #ee3a97;
            background-color: white;
            font-family: "Montserrat";
            text-transform: uppercase;
            font-size: .6rem;
            font-weight: 800;
            box-shadow: 2px 2px 6px 2px #00000036;
            padding: 0 .65rem;
            // position:absolute;
            // bottom: 0;      
            align-self: flex-start;
        }

        .BigSavings {
            font-size: .8rem;
        }

        .ListContainer {
            flex-grow: 1;
            position: relative;
            width: 100%;
            height: auto;

            ul {
                line-height: 100%;
                list-style-type: disc;
                bottom: 0;
                padding: 1rem 1rem 0;
                line-height: 1.4rem;
                width: 100%;
                margin-bottom: 0;

                li {
                    font-size: clamp(12px, 1.4vw, 12.8px);
                    font-family: "montserrat";
                    line-height: 1.65rem;
                }
            }
        }

    }


}

@media (min-width: 1000px) {
    .InnerFlexRow .Details {

        h2.PackageTitle {
            font-size: 1.85rem;
        }
    }
}


.OuterFlexRow:nth-of-type(2n-1) {

    &>div:first-child {}

    &>div:nth-child(2) {

        &.InnerFlexRow {
            position: relative;

            &:before {
                position: absolute;
                background-color: #ccffdd;
                right: 2rem;
                width: calc(((100vw - 100%) / 2) + 100%);
                height: calc(100% - 4rem);
                background: linear-gradient(270deg, #ccffdd, white);
                z-index: -1;
                content: "";
            }
        }
    }

    &>div:last-child {
        background-color: transparent;
    }
}


.OuterFlexRow:nth-of-type(2n) {

    &>div:first-child {
        background-color: transparent;
    }

    &>div:nth-child(2) {

        &.InnerFlexRow {
            position: relative;

            &:before {
                position: absolute;
                background-color: #ccffdd;
                left: 2rem;
                width: calc(((100vw - 100%) / 2) + 100%);
                height: calc(100% - 4rem);
                background: linear-gradient(90deg, #f8aebd, white);
                content: "";
                z-index: -1;
            }
        }
    }

    &>div:last-child {}
}

.OuterFlexRow>div:first-child,
.OuterFlexRow>div:last-child {
    flex-shrink: 1;
    flex-grow: 1;
}

/* Border */

@media (min-width: 550px) {
    .Outer1 {
        &>div:nth-child(1) {
            border-top: 2px dotted #ee3a97;
            padding-top: 2rem;
            padding-bottom: 2rem;
        }

        &>div:nth-child(2) {
            border: 2px dotted #ee3a97;
            border-left: none;
            padding: 2rem;
            padding-left: 2rem;
        }
    }

    .Outer2 {

        &>div:nth-child(2) {
            border: 2px dotted #ee3a97;
            border-right: none;
            border-top: none;
            padding: 2rem;
            padding-right: 2rem;

        }

        &>div:nth-child(3) {
            padding-top: 2rem;
            padding-bottom: 2rem;
        }
    }

    .Outer3 {
        &>div:nth-child(1) {
            border-bottom: 2px dotted #ee3a97;
            padding-top: 2rem;
            padding-bottom: 2rem;
        }

        &>div:nth-child(2) {
            border: 2px dotted #ee3a97;
            border-left: none;
            border-top: none;
            padding: 2rem;
            padding-left: 2rem;
        }
    }
}

@media (max-width: 799px) {
    .Packages .OuterFlexRow div {
        border-right: none;
        border-left: none;
    }
}

@media (max-width: 549px) {
    .Packages .OuterFlexRow {
        div {
            border: none;
        }

        .InnerFlexColumn {

            padding: 0 0 1rem 0;

        }
    }

    .PriceFlexContainer {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .PackagePricing {
            margin: 0;
        }
    }


    .InnerFlexRow {
        padding: 2rem 1rem;
    }

    .OuterFlexRow:nth-of-type(2n) {

        .InnerFlexColumn .PartyImage {
            background: linear-gradient(224deg, #ffebef, #ffebef 70%, white);
        }

    }

    .InnerFlexRow {
        background: linear-gradient(45deg, #f8aebd, white 90%, white);
    }


    .OuterFlexRow:nth-of-type(2n-1) {

        .InnerFlexColumn .PartyImage {
            background: linear-gradient(224deg, #ddf9e6, #ddf9e6 70%, white);
        }

        .InnerFlexRow {
            background: linear-gradient(45deg, #ccffdd, white 90%, white);
        }

    }
}

.Packages i {
    padding-right: 5px;
    padding-left: 3px;
    font-size: 1.1em;
    font-weight: 500;
}



/****** Add Ons section *****/

@mixin mobile-view {
    @media (max-width: 549px) { @content; }
}

@mixin tablet-view {
    @media (max-width: 799px) { @content; }
}

@mixin laptop-view {
    @media (max-width: 999px) { @content; }
}

@mixin desktop-view {
    @media (max-width: 1199px) { @content; }
}

.AddOnBorder {
    border: 2px dotted #ee3a97;
    padding: 2rem;
    max-width: 800px;
    margin: 1rem auto;

    @include tablet-view {
        border: 0px;
    }

    @media (max-width: 549px) {
        padding: 0;
    }
}

.AddOnContainer {
    background-color: #f9edf2;
    padding: 2rem;
}

.AddOnTitle {
    color: #f23597;
    font-family: 'montserrat alternates';
    font-weight: 700;
    font-size: 1.75rem;
}

.AddOnList {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.AddOnItem {
    font-size: clamp(12px, 1.4vw, 12.8px);
    font-family: "montserrat";
    line-height: 1.65rem;
}