.Div {

}

.Nav .Ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.Nav .Ul .Li {
    --c: #683222;
    color: var(--c);
    font-size: 16px;
    border: 0.3em solid var(--c);
    border-radius: 0.3em;
    width: 12em;
    height: 3em;
    font-weight: bold;
    font-family: raleway;
    letter-spacing: 0.1em;
    text-align: center;
    line-height: 2.5em;
    position: relative;
    overflow: hidden;
    z-index: 1;
    transition: 0.4s;
    margin: 1em;
}

.Nav .Ul .Li .Span {
    position: absolute;
    width: 30%;
    height: 100%;
    background-color: var(--c);
    transform: translateY(150%);
    border-radius: 50%;
    left: calc((var(--n) - 1) * 25%);
    transition: 0.2s;
    transition-delay: calc((var(--n) - 1) * 0.05s);
    z-index: -1;
}

.Nav .Ul .Li:hover {
    color: white;
}

.Nav .Ul .Li:hover .Span {
    transform: translateY(0) scale(2);
}

.Nav .Ul .Li .Span:nth-child(1) {
    --n: 1;
}

.Nav .Ul .Li .Span:nth-child(2) {
    --n: 2;
}

.Nav .Ul .Li .Span:nth-child(3) {
    --n: 3;
}

.Nav .Ul .Li .Span:nth-child(4) {
    --n: 4;
}
