@import '../../assets/Stylesheets/mixins.module.scss';

.ContactUsSection {
    background: linear-gradient(white, #fcf7fb 20%);
    padding: 4rem;
    transition: .4s ease-in-out;

    .ContactUsSectionContainer {
        margin: 0 auto;
        max-width: 1200px;

        @include tablet-view {
            padding: 4rem .25rem;
        }

        /****** Form elements *****/

        label {
            font-size: 16px;
            font-weight: 400;
            color: #616161;
        }

        input,
        textarea,
        select {
            box-shadow: -8px -8px 0px 0px #7b369e19;
            border: 2px solid #a0518b;
        }

        button {
            background-color: #a0518b;
            padding: .5rem 3rem;
            margin: 0 1rem 1rem 0;
            white-space: nowrap;
            transition: .1s;
            color: white;
            background-color: #a0518b;
            border: 3px solid #a0518b;

            &:hover,
            &:focus,
            &:active {
                border: 3px solid #a0518b !important;
                background-color: transparent !important;
                color: #a0518b !important;
                transition: .1s;
                box-shadow: none;
            }
        }

        [class*='form-control'] {
            background-color: #fcf7fb;

            &:focus {
                color: #495057;
                background-color: #fff;
                border-color: #a0518b;
                outline: 0;
                box-shadow: none;
            }
        }

        input::-webkit-input-placeholder,
        textarea::-webkit-input-placeholder {
            opacity: .5;
        }

        /******* End form elements**********/


        .HeadingContainer {
            margin-bottom: 4rem;
            transition: .4s ease-in-out;

            @include tablet-view {
                margin-bottom: 2rem;
            }

            .ContactHeading {
                font-weight: 800;
                padding: 0;
                color: #a0518b;
                font-size: clamp(2rem, 8vw, 5rem);
                transform: translate(-10%, 5%);
                line-height: .9;

                &:after {
                    content: none;
                }
            }

            .ContactSubHeading {
                position: relative;
                font-size: 1.2rem;
                font-family: 'montserrat';
                font-weight: 400;
                color: #606060;
                text-align: center;
                line-height: 1.3;

            }
        }

        .Required:after {
            content: "*";
            color: red;
        }
    }
}