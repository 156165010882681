.Form {
    // max-width: 600px;
    width: 100%;
    margin: 0 auto;
    color: white;
}

.QuestionsCommentsLabel {
    text-align: center;
    font-family: "montserrat alternates";
    font-weight: 900;

    &+form input+i:before {
        position: absolute;
        content: "hi";
        background-color: red;
    }
}