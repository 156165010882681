.my-gallery-class {
    margin: 30px auto;
}

.my-bg-image-el {
    background-color: red;
}

.ImageContainer img {
    width: 300px;
    border-radius: 8px;
  }

  .ImageContainer {
    margin: 10px 0;
    list-style-type: none;
    opacity: .7;
    transition: opacity .1s ease-in-out;


  }

  .ImageContainer:hover {
      opacity: .95;
      transition: opacity .1s ease-in-out;

  }
