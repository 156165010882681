@import '../../../assets/Stylesheets/mixins.module.scss';

.Title {
    text-align: center;
    font-family: "montserrat";
    font-weight: 200;
    color: #5800B7;
    // transform: translateY(1.8rem);
    background-color: white;
    padding: 1rem 1.5rem;
    text-align: center;
    margin-top: 2rem;

    &:after {
        width: 1.5rem;
        height: 1px;
        content: '';
        margin: 2rem auto;
        display: block;
        background-color: #bbbbbb;
    }

    @include tablet-view {
        font-size: 32px;
        color: #5800b7;
        text-shadow: none;
        font-weight: 200;
        font-family: 'montserrat alternates';
        background-color: transparent;
        padding: 0;
        margin: 0;
        &:after {
            content: none;

        }
    }
}


@include tablet-view {
    .TitleContainer {
        // background: linear-gradient(90deg, #b7009608, #5800b70b);
        background: #5800b70d no-repeat;
        clip-path: ellipse(70% 100% at 50% 100%);
        background-size: cover;
        padding: 1.75rem 2rem 1rem;
    }
}
.ImageContainer img {
    width: 300px;
  }

  .ImageContainer {
    margin: 10px 0;
    list-style-type: none;
    transition: opacity .15s ease-in-out;
  }

  .ImageContainer:hover {
    opacity: .5;
    transition: opacity .15s ease-in-out;
  }
