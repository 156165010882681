@import "../../assets/Stylesheets/mixins.module.scss";

.Heading {
    padding: 50px;
    line-height: 1.6;
}

.Heading span {
    font-size: 18px;
}

.ReviewRequest {
    margin-top: 30px;
}

.ReviewButton {
    border: 2px solid black;
    color: black;
    border-radius: 5px;
    background-color: white;
    transition: .075s ease-in-out;
    width: 200px;
    text-align: center;
    height: 30px;
    overflow: hidden;

    &:hover {
        cursor: pointer;
    }
}

.item {
    display: inline-block;
    width: 30%;
}

.ReviewRequestButtonContainer {
    width: clamp(375px, 100%, 1200px);
    height: auto;
    margin: 0px auto;
    overflow: hidden;
    position: relative;
    text-align: center;
    z-index: 2;
    transition: .2s;
    position: relative;

}

.ReviewRequestButtonContainerWhiteOut {
    height: 100%;
    width: 100%;
    content: "";
    background-color: #ffffff99;
    z-index: 20;
    position: absolute;
    left: 0;
}

.ReviewRequestButton {
    font-family: 'montserrat';
    font-size: 16px;
    font-weight: 600;
    color: #22C6DD;
    border: 3px solid #22C6DD;
    background-color: white;
    margin: 0px auto;
    border-radius: 3px;
    text-align: center;
    display: inline-block;
    padding: .8rem .6rem;
    cursor: pointer;
    height: auto;

    &.ButtonHoverable:hover {
        color: white !important;
        background-color: #22C6DD !important;
    }
}

.ReviewRequestButtonText {
    white-space: nowrap;
}

.ReviewRequestFormContainer {

    margin: 2rem;
    opacity: 0;
    text-align: left;
    display: none;
    transform: translateY(-10px);
    color: "#676767";
    @include tablet-view {
        margin: 0;
    }

    label {
        font-size: 16px;
        font-weight: 400;
        color: #616161;
    }



    input,
    textarea {
        box-shadow: -8px -8px 0px 0px #368b9e0f;
        border: 2px solid #368b9e;
        padding: 10px;
    }

    button {
        background-color: #368b9e;
        padding: .75rem 1rem;
        border: 0px;
    }
}

.TestBox {
    width: 75%;
    margin: 30px auto;
    border: 2px dotted pink;
    position: relative;
    background-color: white;

    .Tab {
        color: #777777;
        background-color: white;
        width: 60px;
        height: 30px;
        position: absolute;
        border: 2px dotted pink;
        border-radius: 55% 55% 50% 50% / 100% 100% 5% 5%;
        border-bottom: 2px solid white;
        top: -29px;
        left: 0;
        right: 0;
        margin: 0 auto;
        .TabText {

            transform: translate(-8px, -3.3rem);
            font-size: 7rem;
            line-height: 1;
            letter-spacing: -19px;
            font-family: 'Montez';
            text-align: center;

        }
    }

    .TestBoxInner {
        margin: 2rem;
        background-color: #f7f3ea;
        height: 300px;
    }
}

.MoveFormDown {
    transform: translateY(calc(50% + 4rem));
    transition: .35s ease-in-out;
    margin-bottom: 8rem;
}

.CustomerReviewSection {
    max-width: 1200px;
    padding: 2rem;
}