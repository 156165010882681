@import '../../../assets/Stylesheets/variables.module';

.Title {
    position: relative;
    z-index: 1;
    // height: 40%;
    width: 100%;
    background-size:cover;
    // background-image: url("/images/products/cupcakes-valentines-gold.png");

    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    // border: dashed white;
    // border-width: 3px 0 3px 0px;
    margin: 20px 0 20px 0;
}

.Product {
    font-size: 3em;
    margin:0;
    padding:0;
    margin: 0;
    display: inline-block;
    padding: 0 1em;
    font-size: 34px;
    color: rgb(107, 53, 143);
    font-family: "montserrat alternates";
    font-weight: 900;
    text-transform: lowercase;
}

.Product::after {
    width: 10%;
    height: 1px;
    content: '';
    margin: 15px auto 0 auto;
    display: block;
    background-color: #bbbbbb;

}

.PageTitle {
    text-align: center;
    display: block;
}

.TagLine {
    max-width: 600px;
    font-family: "montserrat";
    line-height: 1.4;
    margin:0 auto;
    font-size: 16px;
    font-weight: 500;
    padding: 20px 0;
    color: #666666;
}

.Pricing {
    color: #999999;
    font-weight: 400;
    font-family: 'futura';
}
.TitleTextContainer {
    padding: 20px 0;
}

div[class*='carousel'][class*='slider'] {
    margin: 0 0 50px 0;
}

@media (min-width: 1001px) {
    .TitleText {
    }
}

